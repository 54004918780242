const config = {
  // API_URL: process.env.REACT_APP_API_URL,
  // API_URL: "https://api-ubeatz.my.id/",
  // API_URL: "https://api-dev.ubeatz-developher.online/",
  // API_URL: "http://192.168.18.12:8000/",
  // API_URL: 'http://192.168.68.119:8000/',
  // API_URL: "http://149.129.221.207:7008/",
  // API_HEAVY: "http://149.129.221.207:7006/",

  API_URL: "https://api-django.ubeatz.co.id/",
  API_HEAVY: "https://api-xpress.ubeatz.co.id/",
  // API_URL: "http://172.20.10.2:8000/",
  // API_HEAVY: "http://172.20.10.2:8001/",

  // API_HEAVY: "http://192.168.18.12:8001/",
  // API_URL: "http://192.168.18.12:8000/",
};

export default config;
