import axios from "axios";
import MockAdapter from "axios-mock-adapter";

import config2 from "../config";

const TOKEN =
  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJjb2RlcnRoZW1lcyIsImlhdCI6MTU4NzM1NjY0OSwiZXhwIjoxOTAyODg5NDQ5LCJhdWQiOiJjb2RlcnRoZW1lcy5jb20iLCJzdWIiOiJzdXBwb3J0QGNvZGVydGhlbWVzLmNvbSIsImxhc3ROYW1lIjoiVGVzdCIsIkVtYWlsIjoic3VwcG9ydEBjb2RlcnRoZW1lcy5jb20iLCJSb2xlIjoiQWRtaW4iLCJmaXJzdE5hbWUiOiJIeXBlciJ9.P27f7JNBF-vOaJFpkn-upfEh3zSprYfyhTOYhijykdI";

var mock = new MockAdapter(axios);

export function configureFakeBackend() {
  // let users = [
  //     {
  //         id: 1,
  //         username: 'admin',
  //         password: 'admin',
  //         firstName: 'Test',
  //         lastName: 'User',
  //         role: 'Admin',
  //         token: TOKEN,
  //     },
  //     {
  //         id: 2,
  //         username: 'admin1',
  //         password: 'admin1',
  //         firstName: 'Agun',
  //         lastName: 'User',
  //         role: 'Manager',
  //         token: TOKEN,
  //     },
  // ];

  let users = [];

  mock.onPost("/login/").reply(function (config) {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        // get parameters from post request
        let params = JSON.parse(config.data);

        let str_token = "nkyntczmfcnz993muh7t4k4eyceb3d";

        var request = require("request");

        var options2 = {
          method: "GET",
          url: config2.API_HEAVY + "api/register",
          headers: {},
        };
        request(options2, function (error, response) {
          if (error) throw new Error(error);
          str_token = JSON.parse(response.body).data.api_key;
        });

        var options = {
          method: "POST",
          url: config2.API_URL + "user/login/",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: params.username,
            password: params.password,
          }),
        };
        request(options, function (error, response) {
          if (error) throw new Error(error);
          let filteredUsers = [];
          let user = [];
          if (JSON.parse(response.body).detail === undefined) {
            let obj_array = JSON.parse(response.body).details[0].idrole;
            if (obj_array.idrole_id === 7) {
              resolve([401, { message: "Username or password is incorrect" }]);
            } else {
              if (obj_array.id_access_id.length > 0) {
                var obj = JSON.parse(response.body);
                // console.log(obj);
                user = [
                  {
                    id: obj.id,
                    username: params.username,
                    password: params.password,
                    firstName: obj.firstName,
                    lastName: obj.lastName,
                    role: "Admin",
                    levelrole: obj.details[0].idrole.role_name,
                    token: obj.token,
                    api_key: str_token,
                    navigasi: obj.details[0].idrole.id_access_id,
                    access_outlet: obj.details[0].access_outlet,
                  },
                ];
                // console.log(user);
                filteredUsers = user.filter((user) => {
                  return (
                    user.username === params.username &&
                    user.password === params.password
                  );
                });
                let a = filteredUsers[0];
                resolve([200, a]);
              } else {
                resolve([
                  401,
                  { message: "Username or password is incorrect" },
                ]);
              }
            }
          } else {
            // resolve([401, { message: 'Username or password is incorrect' }]);
            var request = require("request");

            var options2 = {
              method: "GET",
              url: config2.API_HEAVY + "api/register",
              headers: {},
            };
            request(options2, function (error, response) {
              if (error) throw new Error(error);
              str_token = JSON.parse(response.body).data.api_key;
            });

            users.push({
              id: 1,
              username: "admin",
              password: "Ubzadmin123All",
              firstName: "Test",
              lastName: "User",
              role: "Admin",
              levelrole: "AD",
              token: TOKEN,
              api_key: str_token,
              navigasi: "ALL",
            });

            filteredUsers = users.filter((user) => {
              return (
                user.username === params.username &&
                user.password === params.password
              );
            });
            let a = filteredUsers[0];
            resolve([200, a]);
          }
        });
      }, 1000);
    });
  });

  mock.onPost("/register/").reply(function (config) {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        // get parameters from post request
        let params = JSON.parse(config.data);

        // add new users
        let [firstName, lastName] = params.fullname.split(" ");
        let newUser = {
          id: users.length + 1,
          username: firstName,
          password: params.password,
          firstName: firstName,
          lastName: lastName,
          role: "Admin",
          token: TOKEN,
          navigasi: "ALL",
        };
        users.push({ newUser });

        resolve([200, newUser]);
      }, 1000);
    });
  });

  mock.onPost("/forget-password/").reply(function (config) {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        // get parameters from post request
        let params = JSON.parse(config.data);

        // find if any user matches login credentials
        let filteredUsers = users.filter((user) => {
          return user.username === params.username;
        });

        if (filteredUsers.length) {
          // if login details are valid return user details and fake jwt token
          let responseJson = {
            message:
              "We've sent you a link to reset password to your registered email.",
          };
          resolve([200, responseJson]);
        } else {
          // else return error
          resolve([
            401,
            {
              message:
                "Sorry, we could not find any registered user with entered username",
            },
          ]);
        }
      }, 1000);
    });
  });
}
